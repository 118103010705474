import React, { useCallback, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const now = () => {
  return new Date().toISOString()
}

const BlogDateGenerator = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const [date, setDate] = useState(now())

  const onClick = useCallback(
    () => {
      setDate(now())
    },
    [],
  )

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Date Generator" />
      Click here to get today's date for your Markdown blog post:

      <button onClick={onClick}>CLICKY</button>
      <pre>{date}</pre>
    </Layout>
  )
}

export default BlogDateGenerator

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`